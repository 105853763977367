<template>
  <b-container class="pl-0 pl-sm-15 pr-0 pr-sm-15 h-100 pt-10 pb-10">
    <b-row>
      <b-col
        xl="4"
        lg="6"
        md="8"
        sm="10"
        offset-xl="4"
        offset-lg="3"
        offset-md="2"
        offset-sm="1"
        class="ml-auto me-auto mt-10"
      >
        <b-card>
          <h4 class="mb-10">
            {{ $t('loggedOut.title') }}
          </h4>
          <b-button variant="primary" @click="login">
            {{ $t('loggedOut.loginButton.title') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'LoggedOut',
  methods: {
    login() {
      this.$router.push('/start')
    }
  }
}
</script>
